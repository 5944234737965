import { gql } from 'apollo-angular';

export const loginWithPasswordMutation = gql`
  mutation loginWithPassword(
    $email: String!
    $password: String!
  ) {
    loginWithPassword(
      email: $email
      password: $password
    ) {
      loginToken
      expires
    }
  }`;

export const getMeQuery = gql`
  query me {
    me {
      id
      displayName
      userEmail
      avatarImageUrl
      globalRoles
      venueRoles {
        venue {
          name
          id
          address {
            line1
          }
          zones {
            id
            name
          }
          zonesConnection {
            edges {
              node {
                name
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const logoutMutation = gql`
  mutation logout {
    logout
  }
`;