import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from '@auth/models/user.model';
import {
	loginWithPasswordMutation,
	getMeQuery,
	logoutMutation
} from '@shared/gql/auth';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public user$ = new BehaviorSubject<User | null>(null);

	constructor(
		private localStorageService: LocalStorageService,
		private apollo: Apollo,
		private router: Router
	) {}

	isAuthenticated(): boolean {
		const token = this.localStorageService.getItem('access_token');

		if (!token) return false;

		const { loginToken } = JSON.parse(token);
		this.getCurrentUser();
		return !!loginToken;
	}

	login(email: string, password: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.apollo
				.mutate({
					mutation: loginWithPasswordMutation,
					variables: { email, password }
				})
				.subscribe(
					(res: any) => {
						if (res.data.loginWithPassword) {
							this.localStorageService.setItemStringified(
								'access_token',
								res.data.loginWithPassword
							);
							this.getCurrentUser()
								.then(() => resolve(true))
								.catch((error) => {
									reject();
								});
						}

						resolve(true);
					},
					(err) => {
						console.error(err);
						reject(err);
					}
				);
		});
	}

	getCurrentUser(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.apollo
				.query({
					query: getMeQuery
				})
				.subscribe(
					(res: any) => {
						if (res.data.me) {
							this.user$.next(res.data.me as User);
						}

						resolve(true);
					},
					(err) => {
						console.error(err);
						reject(err);
					}
				);
		});
	}

	logout() {
		this.apollo
			.mutate({
				mutation: logoutMutation
			})
			.subscribe((res: any) => {
				if (res.data.logout) {
					this.localStorageService.clear();
					this.router.navigate(['/auth/login']);
					this.user$.next(null);
					this.apollo.client.resetStore();
				}
			});
	}
}
